// @ts-ignore
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// @ts-ignore
import axios from 'axios';

const BASE_URL = 'https://dev.judo.am/api';

export const fetchData = createAsyncThunk('api/fetchData', async (endpoint: string) => {
    const response = await axios.get(`${BASE_URL}/${endpoint}`);
    return response.data;
});

const apiSlice = createSlice({
    name: 'api',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder: { addCase: (arg0: any, arg1: (state: { loading: boolean; }) => void) => { (): any; new(): any; addCase: { (arg0: any, arg1: (state: { loading: boolean; data: any; }, action: { payload: any; }) => void): { (): any; new(): any; addCase: { (arg0: any, arg1: (state: any, action: any) => void): void; new(): any; }; }; new(): any; }; }; }) => {
        builder
            .addCase(fetchData.pending, (state: { loading: boolean; }) => {
                state.loading = true;
            })
            .addCase(fetchData.fulfilled, (state: { loading: boolean; data: any; }, action: { payload: any; }) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                // @ts-ignore
                state.error = action.error.message;
            });
    },
});

export const { actions, reducer: apiReducer } = apiSlice;
export default apiReducer;
